import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom'
import Layout from './layouts/BaseLayout'
import { LandingPage } from './pages/LandingPage'
import { ErrorPage } from './pages/ErrorPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import './styles/base.scss'
import { PlanPage } from './pages/PlanPage'
import { ContactPage } from './pages/ContactPage'
import { ComingSoonPage } from './pages/ComingSoonPage'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />} errorElement={<ErrorPage />}>
      <Route index element={<ComingSoonPage />} />
      <Route path=':lang' element={<ComingSoonPage />} />
      {/* <Route index element={<LandingPage />} />
      <Route path=':lang' element={<LandingPage />} />
      <Route path=':lang/privacy' element={<PrivacyPolicyPage />} />
      <Route path=':lang/plan' element={<PlanPage />} />
      <Route path=':lang/contact' element={<ContactPage />} /> */}
      <Route path='*' element={<NotFoundPage />} />
    </Route>,
  ),
)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
