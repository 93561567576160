import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Container } from '../../components/common/Container'
import styles from './index.module.scss'

export const ComingSoonPage: React.FC = () => {
  const error = useRouteError()
  console.error(error)

  return (
    <div className={styles.page}>
      <Container>
        <div className={styles.page__container}>
          <h1 className={styles.page__title}>Coming Soon..!</h1>
          <a className={styles.page__btn} href='https://prodpics.ai/'>
            main page
          </a>
        </div>
      </Container>
    </div>
  )
}
